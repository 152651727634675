import React, {useState, useRef} from 'react';

function Water() {
  return (
    <div className='campInfo'>
        <p className='specs'>Grades 3-5</p>
        <p className='title'>Water</p>
        <div className='info-container'>
          <div className='info-width'>
            <p className='info'>
              In our Water Project, students will begin learning about the water cycle and fundamental concepts that affect how water interacts with
              the environment around it. As students dive into the project, they will be presented with a diorama featuring a prominent water feature (river, lake, waterfall)
              and objects such as mountains, villages, and forests. The problem students will aim to solve will be to prevent the village from being destroyed from the 
              prominent water feature. Concepts regarding engineering and environmental science will be implemented to protect the village. Midweek, students will
              embark on a community service project, where they will clean up a nearby creek or stream. Finally, real-life examples of the dioramas will be introduced, and
              case studies will be conducted to make strong connections with the real world. 
            </p>
          </div>
          
      </div>
    </div>
  );
}

export default Water;