export const quotes = [
  { text: "A nation that destroys its soil destroys itself.", author: "Franklin Delano Roosevelt" },
  { text: "The Earth is what we all have in common.", author: "Wendell Berry" },
  { text: "Nature does not hurry, yet everything is accomplished.", author: "Lao Tzu" },
  { text: "Water and air, the two essential fluids on which all life depends, have become global garbage cans.", author: "Jacques Yves Cousteau" },
  { text: "The future will either be green or not at all.", author: "Bob Brown" },
  { text: "The Earth does not belong to us; we belong to the Earth.", author: "Chief Seattle" },
  { text: "Rain is grace; rain is the sky descending to the earth; without rain, there would be no life.", author: "John Updike" },
  { text: "We are aware only of the empty space in the forest, which only yesterday was filled with trees.", author: "Anna Freud" },
  { text: "Try to leave the Earth a better place than when you arrived.", author: "Sidney Sheldon" },
  { text: "It will never rain roses: when we want to have more roses we must plant more trees.", author: "George Eliot" }
];